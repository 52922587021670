import * as React from "react";
import { useDialog } from "@shared-ui/dialog-context";
import { useQuery } from "@apollo/client";
import { Link, Action } from "../types";
import { UitkCard } from "@egds/react-core/cards";
import { UitkIcon, UitkIconSize } from "@egds/react-core/icons";
import { NotificationDialog } from "./NotificationDialog";
import { UitkLink } from "@egds/react-core/link";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { UitkParagraph, UitkHeading } from "@egds/react-core/text";
import { UitkSpacing } from "@egds/react-core/spacing";
import { sendAnalytics, getLineOfBusiness, getFunnelLocation } from "./NotificationQueryUtils";
import { ClientChatbotStore } from "src/stores/chatbotStore/ClientChatbotStore";
import { NotificationLocationOnPage, BlossomNotificationDocument } from "__generated__/typedefs";
import { IBexApiFields } from "@shared-ui/bex-api-context";
export interface CustomerNotificationWithDialogProps {
  inputs: {
    context: IBexApiFields["context"];
    lineOfBusiness: string;
    funnelLocation: string;
    location: NotificationLocationOnPage;
  };
  analyticsCallback: (rfrr: string, linkName: string | null) => void;
  className?: string;
  chatbot?: ClientChatbotStore;
}

export const CustomerNotificationWithDialog = (props: CustomerNotificationWithDialogProps) => {
  const [, dialogActions] = useDialog("covid-19-dialog");
  const queryVariables = {
    context: props.inputs.context,
    notificationLocation: props.inputs.location,
    lineOfBusiness: getLineOfBusiness(props.inputs.lineOfBusiness),
    funnelLocation: getFunnelLocation(props.inputs.funnelLocation),
  };
  const { analyticsCallback, className = "" } = props;

  const { data, error, loading } = useQuery(BlossomNotificationDocument, {
    variables: queryVariables,
  });

  if (
    error ||
    loading ||
    !data ||
    !data?.notification ||
    !data?.notification.inlineNotification ||
    !data.notification.inlineNotification.body
  ) {
    return null;
  }

  const { title, body, links } = data.notification.inlineNotification;

  const heading = title?.items[0].text;
  const message = body?.[0]?.items[0].text;

  const bannerLinks = links?.map(({ text, uri, icon, actions }: Link) => ({ text, href: uri?.value, icon, actions }));
  const firstLink = bannerLinks?.shift();

  const onClickLink = (e: React.MouseEvent<HTMLElement>) => {
    dialogActions.openDialog();
    sendAnalytics(firstLink!.actions as Action[], analyticsCallback);
  };

  return (
    <div data-testid="customer-notification-with-dialog" data-stid="customer-notifications" className={className}>
      <NotificationDialog
        inputs={{
          context: props.inputs.context,
          lineOfBusiness: props.inputs.lineOfBusiness,
          funnelLocation: props.inputs.funnelLocation,
        }}
        analyticsCallback={props.analyticsCallback}
      />
      <UitkCard border padded className="travel-advisory-with-chatbot-link">
        <UitkLayoutFlex alignItems="center" space="three">
          <UitkLayoutFlexItem>
            <UitkIcon spotlight={{ theme: "info", background: true }} name="help" size={UitkIconSize.MEDIUM} />
          </UitkLayoutFlexItem>
          <UitkLayoutFlexItem>
            <div>
              <UitkHeading tag="h4">{heading}</UitkHeading>
              <UitkParagraph>{message}</UitkParagraph>
              <UitkSpacing padding={{ blockstart: "two" }}>
                <UitkLayoutFlex space="six">
                  <UitkLink onClick={onClickLink}>
                    <a>{firstLink?.text}</a>
                  </UitkLink>
                </UitkLayoutFlex>
              </UitkSpacing>
            </div>
          </UitkLayoutFlexItem>
        </UitkLayoutFlex>
      </UitkCard>
    </div>
  );
};
