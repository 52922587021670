import {
  CustomerNotification as InlineNotification,
  CustomerNotificationLocation as InlineNotificationLocation,
} from "@shared-ui/customer-notifications";
import { buildReferrer } from "components/utility/UrlUtils";
import { observer } from "mobx-react";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { StoresType, withStores } from "src/stores";
import { UitkSpacing } from "@egds/react-core/spacing";

interface CustomerNotificationsProps extends Pick<StoresType, "page" | "context"> {
  location: string;
  className?: string;
}

const CustomerNotificationsMemo: React.FC<CustomerNotificationsProps> = (props: CustomerNotificationsProps) => {
  const { location, page, className, context } = props;
  const urlLocation = useLocation();

  // @ts-ignore
  if (!page || !page.pageId || !InlineNotificationLocation[location]) {
    return null;
  }

  const referer = buildReferrer(context, urlLocation, true);
  const skipSSR = location === "POPUP" ? true : false;
  return (
    <UitkSpacing margin={{ blockend: "three" }}>
      <div className={className} data-testid="customer-notification-blossom">
        <InlineNotification
          inputs={{
            xPageId: page.pageId,
            // @ts-ignore
            notificationLocation: InlineNotificationLocation[location],
            optionalContext: {
              referer,
            },
          }}
          skipSsr={skipSSR}
        />
      </div>
    </UitkSpacing>
  );
};

export const CustomerNotifications = React.memo(withStores("page", "context")(observer(CustomerNotificationsMemo)));
